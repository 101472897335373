import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    return (
      <main>
        <img
          src={process.env.PUBLIC_URL + '/resources/common/images/FET_loading.webp'}
          alt='loading'
          width={90}
          height={90}
        />
      </main>
    );
  }
}
