import React, { useEffect } from 'react';

const SuspenseLoader = (props) => {
  const styles = {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: '#fff',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'auto',
    padding: 0,
    margin: 0,
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const originalBodyOverflow = document.body.style.overflow;
      const originalHtmlOverflow = document.documentElement.style.overflow;

      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = originalBodyOverflow;
        document.documentElement.style.overflow = originalHtmlOverflow;
      };
    }
  }, []);

  return (
    <div style={{ ...styles }}>
      <img
        src={process.env.PUBLIC_URL + '/resources/common/images/FET_loading.webp'}
        alt='loading'
        width={90}
        height={90}
      />
    </div>
  );
};

export default SuspenseLoader;
