import axios from 'axios';
import * as errorUtil from '../utils/errorUtil';

export const callApi = (apiPathName, vin, transId, pageAction, useSubmitLoading) => {
  let configTransId = '';
  if (transId) {
    configTransId = transId;
  }
  let page = '';
  if (pageAction != null) {
    page = pageAction;
  } else {
    page = window.location.pathname;
  }
  let config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      targetURL: window.location.href,
      pageAction: page,
      channelId: window.channelId,
      transId: configTransId,
      isRest: true,
    },
    withCredentials: true,
  };
  // 送出時，是否跳出 loading 遮罩
  if (useSubmitLoading) {
    //導頁動畫設定
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    $html.classList.add('is-loading');
    $html.classList.add('is-loading-block');
    $body.classList.add('is-loading');
    $html.classList.remove('fade-in');
    $body.classList.remove('fade-in');
  }
  //設定call API的限制時間
  axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIMEOUT);
  return axios
    .post(apiPathName, vin, config)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      if (apiPathName.includes('/eservice/api/')) {
        window.location.href = process.env.PUBLIC_URL + '/resultController/ResultPage?channelId=' + window.channelId;
      }
    })
    .finally(() => {
      // 移除 loading 遮罩
      if (useSubmitLoading) {
        //導頁動畫設定
        const $html = document.getElementsByTagName('html')[0];
        const $body = document.body;
        $html.classList.remove('is-loading');
        $html.classList.remove('is-loading-block');
        $body.classList.remove('is-loading');
        $html.classList.add('fade-in');
        $body.classList.add('fade-in');
      }
    });
};

export const callApiGet = (apiPathName, vin, contentType, responseType, transId) => {
  responseType = responseType || 'json';
  contentType = contentType || 'application/json;charset=utf-8';

  let configTransId = '';
  if (transId) {
    configTransId = transId;
  }

  let config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: responseType,
    headers: {
      'Content-Type': contentType,
      targetURL: window.location.href,
      pageAction: window.location.pathname,
      channelId: window.channelId,
      transId: configTransId,
      isRest: true,
    },
    withCredentials: true,
  };

  return axios
    .post(apiPathName, vin, config)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {});
};

/**
 *  checkPageAuthorization，
 *  驗證權限，
 *  propData是從上一層傳來的this.props，為了發生錯誤時重導頁面用
 */
export const checkPageAuthorization = (propData, fastLoginUrl, isCheckLater) => {
  var pass = false;
  return callApi('/eservice/api/authorizationController/checkPageAuthorization', {})
    .then((data) => {
      if (isCheckLater) {
        // 驗證移至功能頁面實作
        return data;
      }

      if (data == null) {
        // 登入頁面
        window.location.href = data.fetnetAuthUrl;
      } else if (
        data.processResult != null &&
        data.processResult.status != null &&
        data.processResult.status == 's' &&
        data.goFakeLogin
      ) {
        //登入時需要導去快速登入。
        propData.history.push(fastLoginUrl);
      } else if (data.fetnetAuthUrl != null && data.fetnetAuthUrl != '') {
        // 登入頁面
        window.location.href = data.fetnetAuthUrl;
      } else if (
        data.processResult != null &&
        data.processResult.status != null &&
        data.processResult.status == 's' &&
        data.userHaveAuthority
      ) {
        // 成功
        data.pass = true;
      } else {
        data.pass = false;
        errorUtil.ToAuthorizationErrorPage(propData);
      }
      return data;
    })
    .catch((error) => {
      errorUtil.ToAuthorizationErrorPage(propData);
    });
};

//call 外部API (處理cross domain問題)
export const callCrossApiGet = (apiPathName, useSubmitLoading) => {
  //debugger;
  // 送出時，是否跳出 loading 遮罩
  if (useSubmitLoading) {
    //導頁動畫設定
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    $html.classList.add('is-loading');
    $html.classList.add('is-loading-block');
    $body.classList.add('is-loading');
    $html.classList.remove('fade-in');
    $body.classList.remove('fade-in');
  }
  //設定call API的限制時間
  axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIMEOUT);
  return axios
    .get(apiPathName, { 'Access-Control-Allow-Origin': '*' })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log('api錯誤，PathName:' + apiPathName);
    })
    .finally(() => {
      // 移除 loading 遮罩
      if (useSubmitLoading) {
        //導頁動畫設定
        const $html = document.getElementsByTagName('html')[0];
        const $body = document.body;
        $html.classList.remove('is-loading');
        $html.classList.remove('is-loading-block');
        $body.classList.remove('is-loading');
        $html.classList.add('fade-in');
        $body.classList.add('fade-in');
      }
    });
};
