import React, { createContext, useContext, useState } from 'react';

const VisibilityContext = createContext();

const VisibilityProvider = ({ children }) => {
  const [showFooter, setShowFooter] = useState(false);

  return <VisibilityContext.Provider value={{ showFooter, setShowFooter }}>{children}</VisibilityContext.Provider>;
};

const useVisibility = () => {
  const context = useContext(VisibilityContext);

  if (!context) {
    throw new Error('useVisibility must be used within a VisibilityProvider');
  }
  return context;
};

export { VisibilityProvider, useVisibility };
