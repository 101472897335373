import React, { useState, useEffect } from 'react';
import { useVisibility } from '../context/VisibilityContext';
import SuspenseLoader from '../components/SuspenseLoader';

import { PageTypes } from './enum';

const MappedComponent = ({ component, pageType }) => {
  const PageComponent = component;
  const { setShowFooter } = useVisibility();
  const [loading, setLoading] = useState(true);

  const pageReadyHandler = (type) => {
    console.log('onPageReady');

    if (loading) setLoading(false);
    setShowFooter(true);
  };

  useEffect(() => {
    if (pageType === PageTypes.Static) pageReadyHandler(pageType);
  }, []);

  if (pageType === PageTypes.Static) return <PageComponent />;
  return (
    <>
      {loading && <SuspenseLoader />}
      <PageComponent onPageReady={(pageType) => pageReadyHandler(pageType)} />
    </>
  );
};

export const renderPageComponent = (component, pageType = 0) => {
  return <MappedComponent component={component} pageType={pageType} />;
};
